.project-page-container {
  padding: 10px 50px;
  color: white;
}

.project-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-dark-gray);
}

.title-dropdown-container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.title-dropdown-container h3 {
  color: white;
}

.dropdown-container {
  width: 200px;
  position: absolute;
  background-color: var(--color-charcoal);
  margin: 0 20px;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  top: 60px;
  left: 220px
}

.open-modal-button-project {
  margin: none;
}

.dropdown-toggle {
  cursor: pointer;
}

.project-page-info {
  display: flex;
  align-items: center;
  gap: 15%;
}

.project-page-section-title {
  color: white;
  font-size: 1.4em;
  border-bottom: .5px solid var(--color-medium-gray);
  width: fit-content;

}

.project-page-section-text {
  margin-top: 25px;
  font-size: 1em;
}

.project-page-task-list {
  margin-top: 20px;
}

.project-page-add-task {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  width: fit-content;
  gap: 5px;
  border: 1px solid var(--color-medium-gray);
  border-radius: 5px;
}

.project-page-plus-svg path {
  fill: var(--color-medium-gray);
  height: 20px;
  width: 20px;
}

.open-modal-button-task {
  background-color: transparent;
  color: var(--color-medium-gray);
  /* border: 1px solid var(--color-medium-gray); */
  border-radius: 5px;
  border: none;
  padding: 0;
  font-size: 18px;

}
