.team-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.team-list {
    padding: 10px;
}

.team-name {
    margin-bottom: 10px;
}

.member-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 16px;
    padding-top: 10px;
}

.member-initials {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fc979a;
    color: #1E1F21;
    font-weight: bold;
    font-size: 16px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 50%;
    font-size: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.5s ease;
    cursor: pointer;
}

.member-initials.show {
    opacity: 1;
    transform: translateY(0);
}

.member-tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1E1F21;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
}

.member-initials:hover+.member-tooltip {
    opacity: 1;
}

.member-initials:hover {
    color: #ffffff;
    transform: scale(1.1);
}

.loading-gif {
    height: 20px;
    width: L 20px;
}

.project-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


.project-icon {
    color: rgb(0, 255, 255);
    transform: rotate(45deg);
}

.project-name {
    margin-right: 100px;
}


.button {
    margin-top: 16px;
}

/* CREATE X BUTTON */
.open-modal-button-add-team {
    border: none;
    background-color: transparent;
    color: var(--color-light-gray);
    font-weight: var(--font-regular);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.open-modal-button-add-team::before,
.open-modal-button-add-team::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    background-color: var(--color-light-gray);
}

.open-modal-button-add-team::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: background-color 0.3s ease;
}

.open-modal-button-add-team::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: background-color 0.3s ease;
}

.open-modal-button-add-team:hover::before,
.open-modal-button-add-team:hover::after {
    background-color: var(--color-medium-gray);
}

.open-modal-button-add-team:hover {
    transform: scale(1) .4s;
}
