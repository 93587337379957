.header-button-container>*+* {
    margin-left: 30px;
}

.open-modal-button-button-login {
    border: none;
    background-color: transparent;
    color: var(--color-dark-gray);
}

.open-modal-button-button-get-started {
    border: 2px solid black;
    background-color: black;
    color: white;
}

.hero-container {
    padding-top: 100px;
}

.hero-image {
    max-width: 500px;
}

.landing-page-title {
    color: black;
}

.button-transparent {
    border: 2px solid black;
    background-color: transparent;
}

.cta-buttons>*+* {
    margin-left: 30px;
}

.cta-buttons>* {
    padding: 12px 25px;
}
