.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: white;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-date {
  font-size: 18px;
}

.dashboard-greeting {
  font-size: 24px;
}

.dashboard-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
}

.split-container {
  background-color: var(--color-dark-gray);
  border-radius: 10px;
  border: .5px solid var(--color-light-gray);
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  min-width: 200px;
  padding: 20px;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
}

.split-container.task-container {
  max-height: 400px;
  flex-grow: 0;
  overflow-y: auto;
}

.split-container.project-container {
  max-height: 600px;
  flex-grow: 0;
  overflow-y: auto;
}

@media (max-width: 1100px) {
  .dashboard-content {
    flex-direction: column;
    align-items: center;
  }

  .split-container {
    width: 400px;
  }

}

.task-list {
  margin-top: 5px;
  width: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
}

.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  border-bottom: .5px solid var(--color-medium-gray);
}

.open-modal-button-task-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 180px;
  border: none;
  background-color: transparent;
  color: white;
  text-align: start;
  padding: 2px;
}

.task-due-date {
  overflow-x: hidden;
  width: 80px;
}

.task-link {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease;
  padding: 5px;
  cursor: pointer;
  width: 100%;
}

.task-item:hover,
.project-item:hover {
  color: var(--color-medium-gray);
}

.add-task-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  flex-grow: 1;
}

.add-task-text-container {
  display: flex;
  align-items: center;
  border-bottom: .5px solid var(--color-medium-gray);
  width: 95%;
  margin: 0 auto;
}

.open-modal-button-add-task {
  border: none;
  background-color: transparent;
  color: var(--color-medium-gray);
  font-weight: var(--font-regular);
  padding: 0;
  font-size: 14px;
}

.add-item {
  width: 100%;
}

.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  margin-top: 10px;
  width: 100%;
}

@media (max-width: 500px) {
  .project-list {
    grid-template-columns: 1fr;
  }
}

.project-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: cadetblue;
  border-radius: 5px;
  padding: 10px;
}

.plus-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
}

.project-item {
  display: flex;
  align-items: center;
  gap: 20px;
  color: white;
}

.create-project-button {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-medium-gray);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.open-modal-button-create-project {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--color-medium-gray);
  background-color: transparent;
  color: var(--color-medium-gray);
  border-radius: 5px;
  height: 44px;
  width: 44px;
}

.open-modal-button-create-project-text {
  border: none;
  background-color: transparent;
  color: var(--color-medium-gray);
  font-weight: var(--font-medium);
  font-size: 18px;

}
