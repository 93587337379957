.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    height: 400px;
}

.header {
    text-align: center;
}

.header {
    font-size: 35px;
}

.subheading-login {
    color: gray;
    font-size: 16px;
    margin-bottom: 20px;
}

.form-field {
    margin-bottom: 15px;
}

.form-field-label {
    margin-bottom: 5px;
}

.form-field-input {
    padding: 8px;
    border: 1px solid lightgray;
    border-radius: 4px;
    color: gray;
    width: 300px;
}

.login-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    width: 300px;
    cursor: pointer;
}

.login-btn:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.login-demo-btns {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.demo-btn {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: green;
    color: white;
    border: none;
    text-decoration: underline;
    cursor: pointer;
}

.error-list {
    font-size: 12px;
    color: red;
    padding-bottom: 20px;
}

@media screen and (max-width: 480px) {
    .login-form-container {
        width: 100%;
    }

    .login-form-container form {
        width: 100%;
    }

    .login-form-container .login-btn {
        width: 100%;
    }
}
