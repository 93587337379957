.sidebar-content {
    width: 240px;
    background-color: var(--color-dark-gray);
    color: white;
}

.sidebar-header {
    font-weight: var(--font-medium);
    padding-left: 30px;
    padding-top: 50px;
    cursor: pointer;
}

.sidebar-navigation-container {
    margin: 50px 0px;
}

.sidebar-navigation>li {
    padding: 10px 0px;
    font-size: 18px;
    color: var(--color-medium-gray);
}

.sidebar-navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sidebar-tab {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
}

.open-modal-button-add-team-shortcut {
    position: relative;
    padding: 0;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    color: var(--color-medium-gray);
    font-size: 24px;
    cursor: pointer;
}

.open-modal-button-add-team-shortcut::before,
.open-modal-button-add-team-shortcut::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 2px;
    background-color: var(--color-medium-gray);
    transform: translate(-50%, -50%);
}

.open-modal-button-add-team-shortcut:before {
    transform: translate(-50%, -50%) rotate(90deg);
}

.open-modal-button-add-team-shortcut:hover::before,
.open-modal-button-add-team-shortcut:hover::after {
    background-color: #333;
}


.sidebar-navigation>li {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    font-size: 18px;
    color: var(--color-medium-gray);
}

.sidebar-navigation>li:not(:last-child) {
    margin-bottom: 10px;
}

.second-tab-item {
    margin-left: 15px;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-tab:hover {
    background: #565557;
}

.sidebar-content {
    display: flex;
    flex-direction: column;
}


.team-name {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
}

.team-name:hover {
    color: var(--color-medium-gray);
}



.team-arrow {
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid var(--color-medium-gray);
    transition: transform 0.3555s;
    margin-right: 2px;
}

.team-arrow.rotated {
    transform: translateY(-70%) rotate(315deg);
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--color-medium-gray);
    width: 6px;
    height: 6px;
}

.team-dropdown {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.team-dropdown.open {
    opacity: 1;
}
