#create-project-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-charcoal);
    width: 500px;
    border-radius: 10px;
    padding: 10px;
    color: var(--color-medium-gray);
}

.create-project-input {
    width: 100%;
    padding: 10px;
    border: 1px dashed gray;
    border-radius: 20px;
    outline: none;
    background-color: transparent;
    color: gray;
}

.form-field select {
    width: fit-content;
    padding: 10px;
    border: 1px dashed gray;
    border-radius: 20px;
    outline: none;
    background-color: transparent;
    color: gray;
}

.form-field select option {
    background-color: var(--color-charcoal);
    color: var(--color-medium-gray);
}

.button-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.create-button,
.cancel-button {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: white;
}

.create-button {
    background-color: darkgreen;
}

.cancel-button {
    background-color: var(--color-medium-gray);
}

.create-button:hover,
.cancel-button:hover {
    opacity: 0.8;
}

.create-project-modal-container .form-field input::placeholder {
    color: gray;
}
