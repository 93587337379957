.team-page {
    background-color: #1e1f21;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
}

.team-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-dark-gray);
}

.profile-dropdown {
    z-index: 9999;
}

.dropdown-content {
    background-color: var(--color-dark-graygray);
}

.section-heading {
    margin-bottom: 10px;
    position: relative;
}

.section-line {
    border: none;
    border-top: 1px solid #ccc;
    position: absolute;
    width: 100%;
    top: 80%;
    margin-top: 10px;
}

/* .members-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
} */

.member-list-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.member-item-page {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
}

.member-initials-page {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fc979a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.member-initials-page:hover {
    color: #ffffff;
    transform: scale(1.1);
    cursor: default;
}

.member-icon-page {
    color: #1e1f21;
}


.member-tooltip {
    display: none;
    position: absolute;
    background-color: #1e1f21;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.member-item-page:hover .member-tooltip {
    display: block;
}

.add-member-button {
    display: flex;
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    border: 4px white dotted;
    cursor: pointer;
    margin-right: 120px;
    padding: 2px;
}

.add-member-button:hover {
    color: var(--color-light-gray);
    cursor: pointer;
}

.add-member-button span {
    display: inline-block;
    text-align: center;
}

.add-team {
    margin-right: 50px;
}

.add-member-text {
    display: flex;
    gap: 5px;
    margin: 10px 30px 0 60px;
    opacity: .8;
    color: var(--color-light-gray);
    font-weight: lighter;
}

.add-member-text span {
    white-space: nowrap;
}

.member-name-page {
    flex-grow: 1;
    font-size: 12px;
    margin-top: 20px;
}

.projects-section .section-heading::after,
.members-section .section-heading::after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
}

.projects-section .section-heading::after {
    left: 0;
}

.members-section .section-heading::after {
    right: 0;
}


.project-list-page {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
}


.project-item {
    padding: 8px;
}

.project-item-page {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.project-icon-page {
    width: 20px;
    height: 20px;
    background-color: #66a3ff;
    display: inline-block;
    margin-right: 10px;
}

.project-name-page {
    flex-grow: 1;
}

.project-page-info {
    display: flex;
        flex-wrap: wrap;
        gap: 15px;
}


title-dropdown-container {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

.title-dropdown-container h3 {
    color: white;
}

.dropdown-container {
    width: 200px;
    position: absolute;
    background-color: var(--color-charcoal);
    margin: 0 20px;
    border: 1px solid var(--color-light-gray);
    border-radius: 5px;
    top: 60px;
    left: 220px;
    z-index: 9999;
}

.open-modal-button-project {
    margin: none;
}

.dropdown-toggle {
    cursor: pointer;
}
